import axios from "axios";

const instance = axios.create({
  baseURL: "https://unms.keviwlan.hu/nms/api/v2.1",
  timeout: process.env.VUE_APP_API_TIMEOUT || 40000,
  headers: {
    "x-auth-token": "f4c75448-f098-4db0-8ce0-683aa7b999ed",
  },
});

export const UNMSAPI = {
  getSites: async () => {
    const { data } = await instance.get("sites?type=site");
    return data;
  },
};
