<template>
    <v-container fluid>
        <v-card>
            <v-card-text>
                <v-text-field v-model="search" prepend-icon="mdi-magnify" label="Keresés" hide-details single-line />
                <v-data-table :items="sitesForTable" :loading="loading" :headers="tableHeaders"
                    :footer-props="{ itemsPerPageOptions: [30, 50] }" :search="search">
                    <template v-slot:item.contact="{ item }">
                        <ul>
                            <li v-if="item.contactName">{{ item.contactName }}</li>
                            <li v-if="item.contactPhone">{{ item.contactPhone }}</li>
                        </ul>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { UNMSAPI } from '../api/unms';

export default {
    name: "UMMSSitesPage",
    data: () => ({
        loading: true,
        sites: [],
        sitesForTable: [],
        tableHeaders: [
            {
                text: "Központ neve",
                sortable: true,
                value: "name"
            },
            {
                text: "Cím",
                sortable: true,
                value: "address"
            },
            {
                text: "Elérhetőség",
                sortable: false,
                value: "contact"
            }
        ],
        search: "",
    }),
    mounted: async function () {
        this.loading = true;
        this.$store.commit("app/setTitle", "Központok");
        this.sites = await UNMSAPI.getSites();

        this.sitesForTable = this.sites.map((site) => {
            return {
                name: site.identification.name,
                address: site.description.address,
                contactName: site.description.contact.name,
                contactPhone: site.description.contact.phone
            };
        });
        this.loading = false;
    }
};
</script>